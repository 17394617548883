<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <b-spinner />
    </div>
    <div v-else>
      <label for="rejectionMessage" class="thin">Decline message to user(Optional)</label>
      <b-form-group>
        <b-form-textarea
          id="rejectionMessage"
          max-rows="2"
          v-model="rejectionMessage"
          aria-describedby="counter"
          :formatter="formatMessage"
        />
        <b-form-text id="counter"
          >{{ rejectionMessage.length }} / 200</b-form-text
        >
      </b-form-group>
      <div>
        <b-button variant="primary" @click="cancel()"> Decline </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import handleApiError from "../../shared/apiErrorUtil";
import {mapActions} from "vuex";

export default {
  name: "JoinRequestAction",
  props: ["orgId", "requestId"],
  data() {
    return {
      rejectionMessage: "",
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("joinRequestStore", ["reject"]),
    formatMessage(input) {
      const max = 200;
      return input.length > max ? input.substring(0, max) : input;
    },
    cancel() {
      this.isLoading = true;
      this.reject({
        orgId: this.orgId,
        requestId: this.requestId,
        rejectionMessage: this.rejectionMessage,
      })
        .then(() => {
          this.$emit("reject-request");
        })
        .catch(error => {
          this.isLoading = false;
          handleApiError(error, this);
        });
    },
  },
};
</script>

<style scoped>
.thin {
  font-weight: 400;
}
</style>
