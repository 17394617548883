<template>
  <!-- Edit view of Bank Accounts -->
  <div>
    <div class="col-lg-8 col-md-10 col-sm-10">
      <div class="edit_row">
        <label class="form_label col-sm-3">Nickname</label>
        <b-form-input
          :maxlength="35"
          class="form_input col-sm-9"
          id="nickname"
          v-model="$v.bankaccount.nickname.$model"
          :state="null"
          placeholder="Optional"
        >
        </b-form-input>
        <div class="mb-2 text-center del_mod_section">
          <clickable-icon
            icon="x-circle"
            id="removeButton"
            variant="danger"
            sr-label="remove"
            @click="remove(bankaccount)"
          />
        </div>
      </div>
      <div class="edit_row">
        <b-form-invalid-feedback
          class="mt-0 offset-sm-3 offset-6"
          :state="validateState('nickname')"
        >
          {{ errMsgNickname }}
        </b-form-invalid-feedback>
      </div>
      <div class="edit_row">
        <label class="form_label col-sm-3">Name</label>
        <b-form-input
          class="form_input col-sm-9"
          id="acctname"
          v-model="$v.bankaccount.name.$model"
          :state="validateState('name')"
          :maxlength="32"
          placeholder=""
        >
        </b-form-input>
      </div>
      <div class="edit_row">
        <b-form-invalid-feedback
          class="mt-0 offset-sm-3 offset-6"
          :state="validateState('name')"
        >
          Required
        </b-form-invalid-feedback>
      </div>
      <div v-if="isNzbn">
        <div class="edit_row flex-wrap flex-lg-nowrap">
          <label class="form_label col-sm-3 col-6">Account number</label>
          <b-form-input
            class="form_input col-lg-2 col-md-3 col-sm-2 col-6 size-xs"
            id="bankCode"
            :maxlength="2"
            v-model="$v.bankaccount.bankCode.$model"
            :state="validateState('bankCode')"
            placeholder=""
          >
          </b-form-input>
          <b-form-input
            class="form_input col-lg-2 col-md-3 col-sm-2 col-6 ml-1"
            id="branchCode"
            :maxlength="4"
            v-model="$v.bankaccount.branchCode.$model"
            :state="validateState('branchCode')"
            placeholder=""
          >
          </b-form-input>
          <b-form-input
            class="form_input col-sm-3 col-6 ml-1"
            :maxlength="7"
            id="accountNumberBody"
            v-model="$v.bankaccount.accountNumberBody.$model"
            :state="validateState('accountNumberBody')"
            placeholder=""
          >
          </b-form-input>
          <b-form-input
            class="form_input col-lg-2 col-md-3 col-sm-2 col-6 ml-1"
            id="accountNumberSuffix"
            :maxlength="3"
            v-model="$v.bankaccount.accountNumberSuffix.$model"
            :state="validateState('accountNumberSuffix')"
            placeholder=""
          >
          </b-form-input>
          <div class="mb-2 text-center del_mod_section">
            <clickable-icon
              icon="x-circle"
              id="removeButton_nz"
              variant="danger"
              sr-label="remove"
              @click="remove(bankaccount)"
            />
          </div>
        </div>
        <div class="edit_row">
          <b-form-invalid-feedback
            class="mt-0 offset-3"
            :state="
              validateState('bankCode') &&
              validateState('branchCode') &&
              validateState('accountNumberBody') &&
              validateState('accountNumberSuffix')
            "
          >
            Must be valid bank account number
          </b-form-invalid-feedback>
        </div>
      </div>
      <div v-else>
        <div class="edit_row flex-wrap flex-lg-nowrap">
          <label class="form_label col-sm-3 col-6">BSB</label>
          <b-form-input
            :maxlength="6"
            class="col-lg-2 col-md-3 col-sm-2 col-6"
            id="bsb"
            v-model="$v.bankaccount.bsb.$model"
            :state="validateState('bsb')"
            placeholder=""
          >
          </b-form-input>
          <label class="form_label col-sm-3 col-6">Account number</label>
          <b-form-input
            :maxlength="9"
            class="form_input col-sm-3 col-6"
            id="accountNumber"
            v-model="$v.bankaccount.accountNumber.$model"
            :state="validateState('accountNumber')"
            placeholder=""
          >
          </b-form-input>
          <div class="mb-2 text-center del_mod_section">
            <clickable-icon
              icon="x-circle"
              id="removeButton"
              variant="danger"
              sr-label="remove"
              @click="remove(bankaccount)"
            />
          </div>
        </div>
        <div class="edit_row">
          <b-form-invalid-feedback class="mt-0 offset-3" :state="validateState('bsb')">
            {{ errMsgBsb }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback class="mt-0 offset-1" :state="validateState('accountNumber')">
            {{ errMsgAcctnum }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import ClickableIcon from "@/components/interface/ClickableIcon";
import {
  minLength,
  maxLength,
  numeric,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "BankAcctInput",
  mixins: [validationMixin],
  components: {ClickableIcon},
  model: {
    prop: "bankaccount",
    event: "input",
  },
  props: {
    bankaccount: Object,
    bankaccountlist: Array,
    index: Number,
    isNzbn: Boolean,
  },
  computed: {
    errMsgBsb: function () {
      const {$dirty, $error} = this.$v.bankaccount["bsb"];
      if ($dirty && $error) {
        if (
          this.$v.bankaccount["bsb"] &&
          this.$v.bankaccount["bsb"].required === false
        ) {
          return "Required";
        } else if (
          this.$v.bankaccount["bsb"] &&
          this.$v.bankaccount["bsb"].numeric === false
        ) {
          return "Must be a number";
        } else if (
          this.$v.bankaccount["bsb"] &&
          this.$v.bankaccount["bsb"].minLength === false
        ) {
          return "Enter 6 digits";
        } else return "";
      } else return "";
    },
    errMsgAcctnum: function () {
      if (
        this.$v.bankaccount["accountNumber"] &&
        this.$v.bankaccount["accountNumber"].required === false
      ) {
        return "Required";
      } else if (
        this.$v.bankaccount["accountNumber"] &&
        this.$v.bankaccount["accountNumber"].numeric === false
      ) {
        return "Must be a number";
      } else if (
        this.$v.bankaccount["accountNumber"] &&
        this.$v.bankaccount["accountNumber"].unique === false
      ) {
        return "Already entered";
      } else return "";
    },
    errMsgNickname: function () {
      if (
        this.$v.bankaccount["nickname"] &&
        this.$v.bankaccount["nickname"].unique === false
      ) {
        return "Already entered";
      } else return "";
    },
  },
  validations() {
    if (this.isNzbn) {
      return {
        bankaccount: {
          nickname: {
            unique(val) {
              if (val === "") return true;
              let isUnique = true;
              this.bankaccountlist.forEach((ar, i) => {
                if (
                  i !== this.index &&
                  ar.nickname === this.$v.bankaccount.nickname.$model
                ) {
                  isUnique = false;
                }
              });
              return isUnique;
            },
          },
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(32),
          },
          bankCode: {
            required,
            numeric,
            minLength: minLength(2),
            maxLength: maxLength(2),
          },
          branchCode: {
            required,
            numeric,
            minLength: minLength(4),
            maxLength: maxLength(4),
          },
          accountNumberBody: {
            required,
            numeric,
            maxLength: maxLength(7),
          },
          accountNumberSuffix: {
            required,
            numeric,
            minLength: minLength(2),
            maxLength: maxLength(3),
          }
        }
      }
    } else {
      return {
        bankaccount: {
          nickname: {
            unique(val) {
              if (val === "") return true;
              let isUnique = true;
              this.bankaccountlist.forEach((ar, i) => {
                if (
                  i !== this.index &&
                  ar.nickname === this.$v.bankaccount.nickname.$model
                ) {
                  isUnique = false;
                }
              });
              return isUnique;
            },
          },
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(32),
          },
          bsb: {
            required,
            minLength: minLength(6),
            numeric,
          },
          accountNumber: {
            required,
            numeric,
            unique(val) {
              if (val === "") return true;
              let isUnique = true;
              this.bankaccountlist.forEach((ar, i) => {
                if (
                  i !== this.index &&
                  ar.bsb === this.$v.bankaccount.bsb.$model &&
                  ar.accountNumber === this.$v.bankaccount.accountNumber.$model
                ) {
                  isUnique = false;
                }
              });
              return isUnique;
            },
          },
        }
      }
    }
  },
  methods: {
    remove(bankacct) {
      this.$emit("remove", bankacct);
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.bankaccount[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.bankaccount.$touch();
      return !this.$v.bankaccount.$invalid;
    },
  },
};
</script>

<style scoped>

.del_mod_section {
  padding-top: 0.38rem;
  margin-left: 2.6rem;
}

.del_mod_section:focus,
.del_mod_section:hover {
  outline: none;
}

.form_label {
  font-weight: 600;
  text-align: right;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.form_input {
  margin-right: 0;
}

.edit_row {
  display: flex;
  vertical-align: middle;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
}

.size-xs {
  max-width: 100px;
}
</style>
