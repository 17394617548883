<template>
  <div>
    <b-modal
      :id="modalId"
      centered
      size="lg"
      title="Delete Organisation?"
      @show="getApplicationSummary()"
    >
      <b-overlay :show="showLoading" rounded="sm">
        <template #default>
          <p class="my-4">
            This will delete the organisation. This action cannot be undone.
          </p>
          <p class="my-4">
            Number of applications affected: <strong>{{ applicationsOwned }}</strong>
            <span v-if="deleteBusy" class="ml-2 mb-1">
              <b-spinner small variant="primary" label="Spinning"></b-spinner>
            </span>
          </p>

          <div v-if="applicationsOwned > 0">
            <b-form-group label="Choose what happens to existing applications:">
              <b-form-radio
                v-model="appReassignOption"
                name="some-radios"
                value="unchanged"
                ><strong>Unchanged:</strong> Ownership remains with existing individual
                owner</b-form-radio
              >
              <b-form-radio
                v-model="appReassignOption"
                name="some-radios"
                value="reassign"
                ><strong>Re-assign:</strong> Re-assign ownership of all applications to
                current user ({{ currentUserName }})
              </b-form-radio>
            </b-form-group>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="secondary" @click="hide"> Cancel </b-button>
        <b-button
          :disabled="showLoading"
          variant="danger"
          @click="deleteOrgSelected()"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";

export default {
  name: "OrgDelete",
  props: ["orgId", "modalId"],
  computed: {
    currentUserName() {
      return this.getCurrentUser().name;
    },
  },
  data() {
    return {
      appReassignOption: "unchanged",
      applicationsOwned: "",
      deleteBusy: false,
      showLoading: false,
    };
  },
  methods: {
    ...mapActions("orgStore", ["fetchOrg", "deleteOrg"]),
    ...mapActions("applyStore", [
      "updateApplicationOwners",
      "fetchApplicationsSummary",
    ]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapActions("auth", ["getToken"]),
    hide() {
      this.$bvModal.hide(this.modalId);
    },
    deleteOrgSelected() {
      if (this.applicationsOwned === 0) {
        this.callDeleteOrgApi();
      } else {
        this.callReassignApi();
      }
    },
    callDeleteOrgApi() {
      this.deleteBusy = true;
      this.deleteOrg(this.orgId)
        .then(() => {
          this.onOrgDeleted();
          this.deleteBusy = false;
        })
        .catch(error => handleApiError(error, this));
    },
    callReassignApi() {
      let reassignUserSgId = null;
      if (this.appReassignOption === "reassign") {
        reassignUserSgId = this.getCurrentUser().sgId;
      }
      this.deleteBusy = true;
      this.getToken()
        .then(response => {
          const data = {
            orgId: this.orgId,
            userSgId: reassignUserSgId,
            token: response,
          };
          this.updateApplicationOwners(data)
            .then(() => {
              this.callDeleteOrgApi();
              this.deleteBusy = false;
            })
            .catch(error => handleApiError(error, this));
        })
        .catch(error => handleApiError(error, this));
    },
    onOrgDeleted() {
      const message = "The organisation has been deleted.";
      this.$bvToast.toast(message, {
        title: "Success",
        autoHideDelay: 5000,
        variant: "success",
        solid: true,
      });
      this.hide();
      this.$router.push({name: "organisations"});
    },
    getApplicationSummary() {
      this.showLoading = true;
      this.getToken()
        .then(response => {
          const data = {
            orgId: this.orgId,
            token: response,
          };
          this.fetchApplicationsSummary(data)
            .then(objOrg => {
              if (objOrg) {
                this.applicationsOwned = objOrg.data.applicationsOwned;
              }
              this.showLoading = false;
            })
            .catch(error => handleApiError(error, this));
        })
        .catch(error => handleApiError(error, this));
    },
  },
};
</script>
