<template>
  <div>
    <b-form-group
      label="Display name"
      label-for="name"
      :state="!$v.name.$error && isValid"
    >
      <b-form-input
        id="name"
        v-model="name"
        :state="!$v.name.$error"
        @input="$v.name.$touch()"
      />
      <b-form-invalid-feedback :state="!this.$v.name.$error">
        The organisation name must be 1-100 characters long.
      </b-form-invalid-feedback>

      <lookup
        :label="label"
        :org="org"
        v-on:duplicate-orgs="onDuplicateOrg"
        v-on:create-org="onOrgDetails"
        v-on:valid-org="onValidOrg"
        v-on:reset-lookup="onResetLookup"
        :edit-org="true"
      ></lookup>

      <div v-if="!isOrgUnique">
        <div class="alert alert-warning">
          <h6>This {{ abnOrNzbn }} is already registered with SmartyFile.</h6>
          You can update this {{ abnOrNzbn }}. Alternatively, you can request to
          join an existing organisation or create a new organisation.
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";
import Lookup from "@/components/org/Lookup";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "OrgEdit",
  props: ["org"],
  data() {
    return {
      name: this.org.name,
      abn: null,
      nzbn: null,
      label: "Business registration number(ABN/NZBN)",
      isOrgUnique: true,
      isValid: true,
    };
  },
  components: {
    Lookup,
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
  },
  computed: {
    abnOrNzbn() {
      return this.abn ? "ABN" : "NZBN";
    },
  },
  methods: {
    onDuplicateOrg(duplicateOrgs) {
      this.isOrgUnique = duplicateOrgs.length < 1;
    },
    onOrgDetails(value, abn, nzbn) {
      this.abn = abn;
      this.nzbn = nzbn;
    },
    onValidOrg(value) {
      this.isValid = value;
    },
    onResetLookup() {
      this.isOrgUnique = true;
      this.isValid = true;
    },
    save() {
      this.$v.$touch();
      if (this.$v.name.$invalid || !this.isValid) {
        return Promise.reject("Invalid organisation details");
      }

      const toUpdate = {
        id: this.org.id,
        name: this.name,
        abn: this.abn,
        nzbn: this.nzbn,
      };

      return this.patchOrg(toUpdate)
        .then(() => "Details Updated")
        .catch(error => {
          handleApiError(error, this);
        });
    },

    ...mapActions("orgStore", ["patchOrg"]),
  },
};
</script>
