<template>
  <div class="mt-4 mb-5" v-if="hasRequests">
    <div>The following users have requested to join your organisation</div>
    <b-table
      :items="requestsToggle"
      :fields="fields"
      primary-key="id"
      striped
      sort-icon-left
    >
      <template v-slot:cell(userName)="data">
        {{ getJoinRequestById()(data.item.id).userName }}
      </template>
      <template v-slot:cell(userEmail)="data">
        {{ getJoinRequestById()(data.item.id).userEmail }}
      </template>
      <template v-slot:cell(message)="data">
        {{ getJoinRequestById()(data.item.id).message }}
      </template>
      <template v-slot:cell(dateRequested)="data">
        {{ getJoinRequestById()(data.item.id).dateRequested | formatDate }}
      </template>
      <template v-slot:cell(actions)="data">
        <clickable-icon
          icon="check-circle"
          variant="info"
          @click="create(data.item.id)"
          sr-label="approve"
          class="pl-1 pr-1"
        />
        <clickable-icon
          icon="x-circle"
          variant="danger"
          @click="data.toggleDetails"
          sr-label="reject"
          class="pl-1"
        />
      </template>
      <template v-slot:row-details="data">
        <join-request-action
          :org-id="getJoinRequestById()(data.item.id).orgId"
          :request-id="data.item.id"
          v-on:reject-request="reload"
        >
        </join-request-action>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ClickableIcon from "../interface/ClickableIcon";
import handleApiError from "../../shared/apiErrorUtil";
import JoinRequestAction from "@/components/joinRequest/JoinRequestAction";
import {formatDate} from "@/shared/filter";

export default {
  name: "JoinRequests",
  components: {JoinRequestAction, ClickableIcon},
  props: {
    organisationId: String,
  },
  computed: {
    ...mapState("joinRequestStore", {
      requests: state => state.joinRequests,
    }),
    hasRequests() {
      return this.requests.length > 0;
    },
  },
  filters: {
    formatDate,
  },
  data() {
    return {
      fields: [
        {key: "userName", label: "Name", sortable: true},
        {key: "userEmail", label: "Email", sortable: true},
        {key: "message", label: "Message"},
        {
          key: "dateRequested",
          sortable: true,
          formatter: this.$options.filters.formatDate,
        },
        {key: "actions", class: "text-right"},
      ],
      requestsToggle: [],
    };
  },
  methods: {
    ...mapActions("joinRequestStore", [
      "fetchJoinRequests",
      "approve",
      "reject",
    ]),
    ...mapGetters("joinRequestStore", ["getJoinRequestById"]),
    create(requestId) {
      // Show add member dialog and insert the email of the user, make it uneditable. alternatively the user cna be created with minimal permissions and then the admin can edit
      const approveData = {
        orgId: this.organisationId,
        requestId: requestId,
      };

      this.approve(approveData)
        .then(() => this.reload())
        .then(() => this.$emit("new-member"))
        .catch(error => {
          handleApiError(error, this);
        });
    },
    reload() {
      this.fetchJoinRequests(this.organisationId)
        .then(() => {
          this.requestsToggle.splice(0, this.requestsToggle.length);
          this.requests.forEach( r => {
            this.requestsToggle.push({
              id: r.id,
            })
          });
        })
        .catch(error => {
          handleApiError(error, this);
        });
    },
  },
  mounted() {
    this.reload();
  },
};
</script>

<style scoped>
td {
  vertical-align: middle;
}
</style>
