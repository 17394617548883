<template>
  <b-modal :id="modalId" size="xl" :title="title" hide-header-close ok-only>
    <role-legend :orgId="orgId" />
  </b-modal>
</template>

<script>
import RoleLegend from "./RoleLegend";

export default {
  name: "RoleLegendModal",
  components: {RoleLegend},
  props: ["modalId", "orgId"],
  computed: {
    title: () => "Role Legend",
  },
};
</script>
