<template>
  <div>
    <h2>Multi-Factor Authentication (MFA)</h2>
    As an extra layer of security, you may require all users in this
    Organisation to use an authenticator mobile app (such as Google
    Authenticator or Authy) each time they log in.

    <b-checkbox v-model="mfaEnabled" switch class="mt-4 mb-5">
      <strong>Enable Multi-Factor Authentication</strong>
    </b-checkbox>
    <b-button variant="primary" :active="buttonEnabled" @click="save">
      Save
    </b-button>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";

export default {
  name: "Security",
  props: ["orgId", "orgMfaEnabled"],
  data() {
    return {
      buttonEnabled: true,
      mfaEnabled: false,
    };
  },
  methods: {
    ...mapActions("orgStore", ["patchOrg"]),
    save() {
      this.buttonEnabled = false;
      const orgData = {
        id: this.orgId,
        mfaEnabled: this.mfaEnabled,
      };
      this.patchOrg(orgData)
        .then(() => {
          this.$bvToast.toast("Setting saved", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch(error => handleApiError(error, this))
        .finally(() => (this.buttonEnabled = true));
    },
  },
  mounted() {
    this.mfaEnabled = this.orgMfaEnabled;
  },
};
</script>
<style lang="scss" scoped>
@import "../../../content/scss/vendor";

:deep(.custom-switch .custom-control-label::after) {
  background-color: $gray-700;
}

:deep(.custom-control-input:checked ~ .custom-control-label::before) {
  border-color: $primary;
  background-color: $primary;
}
</style>
