<template>
  <b-form-group label="Mission statement" label-for="mission">
    <b-form-textarea id="mission" v-model="statement" rows="3" />
  </b-form-group>
</template>

<script>
import {mapActions} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";

export default {
  name: "OtherEdit",
  props: ["org"],
  data() {
    return {
      statement: this.org.missionStatement,
    };
  },
  methods: {
    ...mapActions("orgStore", ["patchOrg"]),
    save() {
      const toUpdate = {
        id: this.org.id,
        missionStatement: this.statement,
      };
      return this.patchOrg(toUpdate)
        .then(() => "Mission Statement Updated")
        .catch(error => {
          handleApiError(error, this);
        });
    },
  },
};
</script>
