<template>
  <div class="adjust">
    <b-icon icon="check-circle" variant="info" v-if="show" font-scale="1.25" aria-label="Yes" />
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: "CheckIcon",
  props: ["show"],
};
</script>
<style scoped>
.adjust {
  padding-top: 0.2em;
}
</style>
