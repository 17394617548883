<template>
  <!-- Read view of the Organisation details -->
  <div class="mb-4">
    <div class="row row-cols-sm-2 row-cols-1">
      <info-line heading="h6" label="Display name" :value="org.name" class="col" />
      <info-line heading="h6" label="ABN" v-if="org.abn" :value="org.abn" class="col" />
      <info-line heading="h6" label="NZBN" v-if="org.nzbn" :value="org.nzbn" class="col" />
    </div>
    <b-skeleton-wrapper :loading="!abn && !nzbn && !apiError">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
          <b-skeleton width="25%"></b-skeleton>
          <b-skeleton width="60%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
          <b-skeleton width="50%"></b-skeleton>
          <b-skeleton width="75%"></b-skeleton>
        </b-card>
      </template>
    </b-skeleton-wrapper>
    <transition name="slide">
      <abn-details v-if="abn" :abn="abn" />
      <nzbn-details v-if="nzbn" :nzbn="nzbn" />
      <template v-if="apiError">
        <b-card class="text-center">
          <b-icon-image></b-icon-image> Details not available
        </b-card>
      </template>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AbnDetails from "./AbnDetails";
import NzbnDetails from "./NzbnDetails";
import InfoLine from "../interface/InfoLine";
import handleApiError from "../../shared/apiErrorUtil";

export default {
  name: "OrgView",
  components: {
    AbnDetails,
    NzbnDetails,
    InfoLine,
  },
  props: ["org"],
  data() {
    return {
      apiError: false,
    };
  },
  computed: {
    ...mapState("abnStore", {abns: state => state.abns}),
    ...mapState("nzbnStore", {nzbns: state => state.nzbns}),
    abn() {
      return this.abns[this.org.abn];
    },
    nzbn() {
      return this.nzbns[this.org.nzbn];
    },
  },
  methods: {
    ...mapActions("abnStore", ["getAbnDetails"]),
    ...mapActions("nzbnStore", ["getNzbnDetails"]),
    handleError(error, msg) {
      this.apiError = true;
      const status = error.status || error.response.status;
      if (status >= 500) {
        handleApiError(error, this, msg);
      } else {
        handleApiError(error, this);
      }
    },
  },
  mounted() {
    if (this.org.abn != null) {
      this.getAbnDetails(this.org.abn).catch(error =>
        this.handleError(
          error,
          "ABN registry is experiencing some issues so we can't verify your ABN. Please try again later."
        )
      );
    } else {
      this.getNzbnDetails(this.org.nzbn).catch(error =>
        this.handleError(
          error,
          "NZBN registry is experiencing some issues so we can't verify your NZBN. Please try again later."
        )
      );
    }
  },
};
</script>
