export const bankacct = function (val) {
  if (!val) return "";
  let bankacct = "";
  bankacct =
    val.substring(0, 3) +
    " " +
    val.substring(3, 6) +
    " " +
    val.substring(6, 10);
  return bankacct;
};
