<template>
  <div v-if="org">
    <b-card class="mb-4" body-class="orgdetails_card">
      <editable-card
        title="Organisation Details"
        :read-view="orgComponents.read"
        :edit-view="orgComponents.edit"
        :child-props="orgProps"
        :isAdmin="isAdmin"
        :show-edit="editOrg"
        border-color="white"
        :show-padding="true"
      />
      <editable-card
        title="Contact Details"
        :read-view="contactComponents.read"
        :edit-view="contactComponents.edit"
        :child-props="contactProps"
        :isAdmin="isAdmin"
        :show-edit="editContacts"
        border-color="white"
        :show-padding="true"
      />
      <editable-card
        title="Bank Accounts"
        :read-view="bankComponents.read"
        :edit-view="bankComponents.edit"
        :child-props="contactProps"
        :isAdmin="isAdmin"
        :show-edit="editContacts"
        border-color="white"
        :show-padding="true"
      />
    </b-card>
    <editable-card
      title="Head of Org"
      :read-view="headOfOrComponents.read"
      :edit-view="headOfOrComponents.edit"
      :child-props="contactProps"
      :isAdmin="isAdmin"
      :show-edit="editContacts"
      class="mb-4"
    />
    <editable-card
      title="Other Details"
      :read-view="otherComponents.read"
      :edit-view="otherComponents.edit"
      :child-props="otherProps"
      :isAdmin="isAdmin"
      :show-edit="editMission"
      class="mb-4"
    />
    <b-button
      v-if="isAdmin"
      :disabled="!isAdmin"
      id="deleteOrgButton"
      class="deleteOrgButton"
      variant="danger"
      @click="showDeleteOrg()"
      >Delete organisation</b-button
    >
    <OrgDelete modal-id="modalConfirmDelOrg" :orgId="orgId"> </OrgDelete>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BankAcctListView from "./BankAcctListView";
import BankAcctEditView from "./BankAcctEditView";
import ContactView from "./ContactView";
import ContactEdit from "./ContactEdit";
import EditableCard from "../interface/EditableCard";
import HeadOfOrgView from "./HeadOfOrgView";
import HeadOfOrgEdit from "./HeadOfOrgEdit";
import InfoLine from "../interface/InfoLine";
import OtherEdit from "./OtherEdit";
import OrgView from "./OrgView";
import OrgEdit from "./OrgEdit";
import OrgDelete from "./OrgDelete";
import {log} from "@/utils/log";

export default {
  name: "OrgDetails",
  components: {
    EditableCard,
    OrgDelete,
  },
  props: {
    orgId: String,
    editContacts: Boolean,
    editMission: Boolean,
    editOrg: Boolean,
  },
  computed: {
    ...mapState("orgStore", {orgs: state => state.orgs}),
    ...mapGetters({
      getOrgById: "orgStore/getOrgById",
    }),
    org() {
      return this.getOrgById(this.orgId);
    },
    isAdmin() {
      return this.isAdminOf()(this.orgId);
    },
    contactProps() {
      return {
        org: this.getOrgById(this.orgId),
      };
    },
    otherProps() {
      return {
        label: "Mission statement",
        value: this.org ? this.org.missionStatement : "",
        org: this.org,
        class: "mb-neg-1",
      };
    },
    orgProps() {
      return {
        org: this.org,
      };
    },
  },
  data() {
    return {
      bankComponents: {
        read: BankAcctListView,
        edit: BankAcctEditView,
      },
      contactComponents: {
        read: ContactView,
        edit: ContactEdit,
      },
      headOfOrComponents: {
        read: HeadOfOrgView,
        edit: HeadOfOrgEdit,
      },
      otherComponents: {
        read: InfoLine,
        edit: OtherEdit,
      },
      orgComponents: {
        read: OrgView,
        edit: OrgEdit,
      },
    };
  },
  methods: {
    ...mapGetters("auth", ["isAdminOf", "getCurrentUser"]),
    ...mapActions("orgStore", ["fetchMembersPage", "fetchOrg"]),
    showDeleteOrg() {
      this.$bvModal.show("modalConfirmDelOrg");
    },
  },
  mounted() {
    this.fetchOrg(this.orgId).catch(error =>
      log.error("Error fetching orgs. " + error)
    );
  },
};
</script>
<style>
.orgdetails_card {
  padding: 0 !important;
}
.slide-enter-active {
  transition: all 0.8s ease-in-out;
}
.slide-enter {
  opacity: 0;
}
</style>
