<template>
  <div>
    <b-table-lite :fields="fields" :items="items" :stacked="breakpoint" striped>
      <template v-slot:head(CUSTOM)>
        <span>Custom</span>
        <b-button
            class="btn btn-xs btn-tip p-0 ml-1 mb-1"
            variant="link"
            :aria-expanded="(selectedTip === 'custom-popover') ? 'true' : 'false'"
            aria-label="Custom Permission Tip"
            id="custom-popover"
            @click="showRoleTips"
        >
          <b-icon
              icon="info-circle"
              variant="primary"
              aria-hidden="true"
          />
          <b-popover
              target="custom-popover"
              triggers="hover"
              placement="right"
          >
            <span>Set a customised permission if none of the default roles suit your users.</span>
          </b-popover>
        </b-button>
      </template>
      <template v-slot:cell(label)="data">
        <div class="d-flex align-items-center">
          <span>{{data.item.label}}</span>
          <b-button
              class="btn btn-xs btn-tip p-0 ml-1 mb-1"
              variant="link"
              :aria-expanded="(selectedTip === `popoverAccessInfo_${data.item.key}`) ? 'true' : 'false'"
              :aria-label="data.item.label + ' Tip'"
              :id="`popoverAccessInfo_${data.item.key}`"
              @click="showRoleTips"
              v-if="data.item.info"
          >
            <b-icon
                icon="info-circle"
                variant="primary"
                aria-hidden="true"
            />
            <b-popover
                :target="`popoverAccessInfo_${data.item.key}`"
                triggers="hover"
                placement="right"
            >
              <span>{{data.item.info}}</span>
            </b-popover>
          </b-button>
        </div>
      </template>
      <template v-slot:cell(ADMIN)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(AUTHOR)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(WRITER)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(READER)="data">
        <check-icon :show="data.value" />
      </template>
      <template v-slot:cell(CUSTOM)="data">
        <span v-if="showEdit">
          <b-form-checkbox
            v-model="selectedFunctions[data.item.key]"
            switch
            @change="startEditing"
          />
        </span>
        <span v-else>
          <check-icon :show="data.value" />
        </span>
      </template>
    </b-table-lite>
    <div v-if="showEdit">
      <div class="mt-3 text-right">
        <b-button
          @click="buttonClicked"
          variant="primary"
          class="mr-3"
          :disabled="!isEditing"
        >
          Save
        </b-button>
        <b-button @click="cancel" variant="dark" :disabled="!isEditing">
          Cancel
        </b-button>
      </div>
    </div>
    <b-modal id="roletips" size="xl" title="Role Tips" @hidden="roleTipHidden" hide-header-close ok-only>
      <dl>
        <div v-for="item in items" :key="item.key">
          <template v-if="item.info">
            <dt>{{ item.label }}</dt>
            <dd>{{ item.info }}</dd>
          </template>
        </div>
        <dt>Custom</dt>
        <dd>Set a customised permission if none of the default roles suit your users.</dd>
      </dl>
    </b-modal>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import CheckIcon from "../interface/CheckIcon";

export default {
  name: "RoleLegend",
  components: {
    CheckIcon
  },
  props: ["orgId", "showEdit"],
  computed: {
    ...mapState({
      orgs: state => state.role.orgs,
      allPermissions: state => state.role.allPermissions,
      user: state => state.auth.currentUser,
    }),
    ...mapGetters("role", {roles: "getRolesWithKey"}),
    customPermissions() {
      if (this.orgId && this.orgs[this.orgId]) {
        return this.orgs[this.orgId];
      }
      return [];
    },

    fields() {
      const fields = [{key: "label", label: "Permissions", class: "fun"}];
      fields.push(
        ...this.roles.map(role => {
          return {
            key: role.value,
            label: role.text,
            formatter: this.hasRole,
            class: "text-center",
          };
        })
      );
      if (this.customPermissions.length === 0 && !this.showEdit) {
        const index = fields.findIndex(r => r.key === "CUSTOM");
        if (index >= 0) {
          fields.splice(index, 1);
        }
      }
      return fields;
    },
    items() {
      let custom = this.customPermissions;
      if (!custom || custom.length <= 0) {
        custom = [];
      }
      return this.allPermissions.map(perm => {
        const roles = [...perm.inRoles];
        if (custom.includes(perm.key)) {
          roles.push("CUSTOM");
        }
        return {
          key: perm.key,
          label: perm.label,
          info: perm.info,
          inRoles: roles,
        };
      });
    },
    needsOrgRead() {
      return this.selectedFunctions.ADMIN;
    },
    removeOrgRead() {
      return !this.selectedFunctions.OF_VIEW;
    },
    needsGrantAppRead() {
      return (
        this.selectedFunctions.GRANT_APP_CREATE ||
        this.selectedFunctions.GRANT_APP_WRITE
      );
    },
    removeGrantAppRead() {
      return !this.selectedFunctions.GRANT_APP_READ;
    },
    breakpoint() {
      return this.showEdit ? "md" : "lg";
    },
  },
  data() {
    return {
      isEditing: false,
      selectedTip: 0,
      selectedFunctions: {
        ADMIN: false,
        OF_VIEW: false,
        GRANT_APP_CREATE: false,
        GRANT_APP_WRITE: false,
        MAYBE_SUBMIT: false,
        GRANT_APP_READ: false,
      },
    };
  },
  methods: {
    ...mapActions("role", ["getCustomPermissions", "setCustomPermissions"]),
    hasRole(value, key, item) {
      return item.inRoles.includes(key);
    },
    buttonClicked() {
      if (this.isEditing) {
        this.save();
      }
      this.isEditing = !this.isEditing;
    },
    startEditing() {
      this.isEditing = true;
    },
    save() {
      const permissions = Object.entries(this.selectedFunctions)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);

      const data = {
        orgId: this.orgId,
        permissions: permissions,
      };

      if (permissions.length <= 0) {
        this.showConfirmDialog().then(confirmed => {
          if (confirmed) {
            this.sendToBackend(data);
          } else {
            this.cancel();
          }
        });
      } else {
        this.sendToBackend(data);
      }
    },
    cancel() {
      this.allPermissions.map(p => {
        this.selectedFunctions[p.key] = this.customPermissions.includes(p.key);
      });
      this.isEditing = false;
      this.$bvToast.toast("Custom permission changes has been discarded", {
        title: "Cancel",
        autoHideDelay: 5000,
        variant: "success",
        solid: true,
      });
    },
    showConfirmDialog() {
      const message =
        "Removing all permissions from the custom role will prevent users in" +
        " this role from performing any action on behalf of your organisation.";
      const question = "Are you sure you want to proceed?";
      const h = this.$createElement;

      return this.$bvModal.msgBoxConfirm(
        [h("p", message), h("div", question)],
        {cancelVariant: "dark"}
      );
    },
    sendToBackend(data) {
      this.setCustomPermissions(data)
        .then(() => this.$root.$emit("bv::refresh::table", "members"))
        .then(() =>
          this.$bvToast.toast("Custom permission updated", {
            title: "Success",
            autoHideDelay: 5000,
            variant: "success",
            solid: true,
          })
        )
        .catch(error => {
          const errorCode = error.status || error.response.status;
          let message =
            "We couldn't save the permissions, please try again later";
          if (errorCode && errorCode === 400) {
            // Something is wrong with the data sent
            message = "Invalid permission set";
          }
          this.$bvToast.toast(message, {title: "Permissions not saved"});
        });
    },
    showRoleTips(e) {
      this.selectedTip = e.currentTarget.id;
      this.$bvModal.show("roletips");
    },
    roleTipHidden() {
      this.selectedTip = null;
    }
  },
  mounted() {
    this.getCustomPermissions(this.orgId)
      .then(permissions => {
        permissions.map(perm => (this.selectedFunctions[perm] = true));
      })
      .catch(error => {
        console.log("Error when requesting the custom permissions: " + error);
      });
  },
  watch: {
    needsOrgRead(needsRead) {
      if (needsRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.OF_VIEW = true;
        });
      }
    },
    removeOrgRead(removeOrgRead) {
      if (removeOrgRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.ADMIN = false;
        });
      }
    },
    needsGrantAppRead(needsRead) {
      if (needsRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.GRANT_APP_READ = true;
        });
      }
    },
    removeGrantAppRead(removeGrantAppRead) {
      if (removeGrantAppRead) {
        // This needs to be done on the next tick for the UI to see the forced value
        this.$nextTick(() => {
          this.selectedFunctions.GRANT_APP_CREATE = false;
          this.selectedFunctions.GRANT_APP_WRITE = false;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../content/scss/vendor";
::v-deep td.fun {
  font-weight: 600;
  background-color: $primary_alpha_10;
}

::v-deep .custom-switch .custom-control-label::after {
  background-color: $gray-700;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: $dark_teal;
  background-color: $dark_teal;
}

.btn.disabled {
  opacity: 0.75;
}

.btn.btn-xs {
  font-size: x-small;
}

.btn.btn-tip:focus {
  box-shadow: none;
}

.modal .btn.btn-tip {
  display: none;
}
</style>
