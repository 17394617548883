<template>
  <b-modal :id="modalId" size="lg" :title="title" hide-header-close ok-only>
    <ul>
      <li v-for="(option, index) of accessOptions" :key="index">
        <strong>{{ option.text }} -</strong> {{ option.description }}
      </li>
    </ul>
  </b-modal>
</template>

<script>
import accessOptions from "../../../i18n/en/accessOptions";
export default {
  name: "AccessLegendModal",
  props: ["modalId"],
  computed: {
    accessOptions: () => accessOptions,
    title: () => "Access Legend",
  },
};
</script>
