let logLevel = "error";
if (process.env.NODE_ENV === "development") {
  logLevel = "debug";
} else if (process.env.NODE_ENV === "test") {
  logLevel = "info";
}

export const log = {
  debug: function (msg) {
    if (logLevel === "debug") {
      console.log("DEBUG: " + msg);
    }
  },
  info: function (msg) {
    if (logLevel === "debug" || logLevel === "info") {
      console.log("INFO: " + msg);
    }
  },
  warn: function (msg) {
    if (logLevel === "debug" || logLevel === "info" || logLevel === "warn") {
      console.warn("WARN: " + msg);
    }
  },
  error: function (msg) {
    if (
      logLevel === "debug" ||
      logLevel === "info" ||
      logLevel === "warn" ||
      logLevel === "error"
    ) {
      console.error("ERROR: " + msg);
    }
  },
  fatal: function (msg) {
    if (
      logLevel === "debug" ||
      logLevel === "info" ||
      logLevel === "warn" ||
      logLevel === "error" ||
      logLevel === "fatal"
    ) {
      console.error("FATAL: " + msg);
    }
  },
};
