<template>
  <div>
    <individual-contact-edit
      :contact="copyOrg"
      ref="contact"
      @addRow="addRow"
      @remove="removeEntry"
      @tagSelect="onTagSelect"
      @addRowAddress="addRowAddress"
    ></individual-contact-edit>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";
import {
  clearEmptyContactFields,
  clearEmptyAddressFields,
  scrollToError,
} from "@/utils/utils";
import IndividualContactEdit from "../interface/IndividualContactEdit";
import {forceUniqueTags} from "@/utils/utils";

export default {
  name: "ContactEdit",
  components: {
    IndividualContactEdit,
  },
  props: ["org"],
  data() {
    return {
      /**
       * A partial copy of the org to avoid directly modifying data from the store
       */
      copyOrg: {
        id: this.org.id,
        emails: this._.cloneDeep(this.org.emails),
        phoneNumbers: this._.cloneDeep(this.org.phoneNumbers),
        websites: this._.cloneDeep(this.org.websites),
        addresses: this._.cloneDeep(this.org.addresses),
        headOfOrg: this._.cloneDeep(this.org.headOfOrg),
      },
    };
  },
  methods: {
    ...mapActions("orgStore", ["patchOrg"]),
    addRow(arrayName, name) {
      const row = {
        [name]: "",
        tags: [],
      };
      if (this.copyOrg[arrayName].length === 0) {
        row.tags = ["Primary"];
      }
      this.copyOrg[arrayName].push(row);
    },
    addRowAddress() {
      const row = {
        addressLine1: "",
        addressLine2: "",
        country: "",
        postcode: "",
        state: "",
        suburbLocality: "",
        tags: [],
      };
      if (this.copyOrg.addresses.length === 0) {
        row.tags = ["Primary"];
      }
      this.copyOrg.addresses.push(row);
    },
    checkErrors() {
      return this.$refs.contact.invalidFields();
    },
    clearEmptyData() {
      clearEmptyContactFields(this.copyOrg.emails, "email");
      clearEmptyContactFields(this.copyOrg.phoneNumbers, "number");
      clearEmptyContactFields(this.copyOrg.websites, "url");
      clearEmptyAddressFields(this.copyOrg.addresses);
    },
    save() {
      // First clear fully empty rows
      this.clearEmptyData();
      const checkedErrors = this.checkErrors();
      if (checkedErrors.length > 0) {
        this.scrollToError(checkedErrors[0]);
        return Promise.reject("Errors detected when saving contact details");
      }
      this.copyOrg.emails.forEach(x => (x.email = x.email.toLowerCase()));
      return this.patchOrg(this.copyOrg)
        .then(() => "Contacts Updated")
        .catch(err => {
          // TODO when API fails validation this should be caught and shown as validation errors
          // Check the type of error, if it's a validation error display here.
          // Rethrow the error to the parent component to indicate the operation failed
          handleApiError(err, this);
          throw err;
        });
    },
    onTagSelect(name, contactArray, selectedEntry) {
      this.copyOrg[name] = forceUniqueTags(contactArray, selectedEntry);
    },
    removeEntry(name, obj) {
      this.copyOrg[name] = this.copyOrg[name].filter(
        element => element !== obj
      );
    },
    scrollToError(error) {
      scrollToError(error); // from utils
    },
  },
};
</script>
